.chat {

}

.chat .chat-fab {
    width:64px;
    height:64px;
    position:absolute;
    bottom:128px;
    right:64px;
    z-index:1000;
}

.chat .new-message {
    animation: notify 2s ease-in infinite;
}

@keyframes notify {
    0% {
        box-shadow: 0px 0px 4px 4px rgba(255,185,0,0.4);
    }
    50% {
        box-shadow: 0px 0px 8px 8px rgba(255,185,0,0.8);
    }
    100% {
        box-shadow: 0px 0px 4px 4px rgba(255,185,0,0.4);
    }
}