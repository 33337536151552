.actions {
    position:absolute;
    top:12%;
    left:15.5%;
    bottom:30%;
    right:23%;
    background-color: white;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
}

@media screen and (max-width: 1300px) {
    .actions {
        top:19%;
        bottom:1%;
        right:1%;
        left:1%;
    }
}