.actions-menu {
    position:absolute;
    left:15.5%;
    bottom:88%;
    right:23%;
    height:32px;
    z-index: 20;
}

.actions-menu a {
    position:relative;
    display:inline-block;
    font-size: 18px;
    bottom:0;
    color:#202124;
    height:32px;
}

.actions-menu .trip-management-menu a {
    width:25%;
}

.actions-menu .administration-menu a {
    width:50%;
}

.actions-menu .master-data-menu a {
    width:50%;
}

.actions-menu .appointment-management-menu a {
    width:50%;
}

.actions-menu .settings-menu a {
    width:50%;
}

.actions-menu a:hover {
    color:#FAB900;
}

.actions-menu a:after {
    left:50%;
    background: none repeat scroll 0 0;
    bottom:0;
    content:'';
    display:block;
    height:2px;
    position:absolute;
    background:#FAB900;
    transition:width 0.2s ease 0s, left 0.2s ease 0s;
    width:0;
}

.actions-menu a:hover:after {
    bottom:0;
    width: 100%;
    left:0;
    color:#FAB900;
}

.actions-menu .active:after {
    bottom:0;
    width:100%;
    left:0;
    color:#FAB900;
}

.actions-menu .active {
    color:#FAB900;
    font-weight: bold;
}

.actions-menu #counter {
    background-color: #FAB900 !important;
    color: #FAB900 !important;
    width:32px !important;
    height:32px !important;
    border-radius: 16px !important;
}

.actions-menu .notification {
    width:0;
    height:0;
    background:#fab900;
    float:left;
    position:absolute;
    border-radius:2px;
    top:12px;
    left:10%;
    animation: notify 2s ease-in infinite;
}

@keyframes notify {
    0% {
        box-shadow: 0px 0px 4px 4px rgba(255,185,0,0.4);
    }
    50% {
        box-shadow: 0px 0px 8px 8px rgba(255,185,0,0.8);
    }
    100% {
        box-shadow: 0px 0px 4px 4px rgba(255,185,0,0.4);
    }
}

@media screen and (max-width: 1300px) {
    .actions-menu {
        left:1%;
        right:1%;
        bottom:75%;
        top:15%;
    }
}

@media screen and (max-width: 600px) {
    .actions-menu .trip-management-menu h2 {
        font-size:10px;
    }
}