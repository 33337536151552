.trip-request-overview {
    width:100%;
    height:100%;
    text-align:left;
}

.trip-request-overview h1 {
    margin:32px 0 0 32px;
}

.trip-request-overview .search-input {
    margin:16px 0 0 32px;
    width:128px;
}

.trip-request-overview .search-select {
    margin:16px 0 0 32px;
    width:128px;
    float:left;
}

.trip-request-overview .search-button {
    margin:24px 0 0 16px;
}

.trip-request-overview .add-fab {
    position:absolute;
    bottom:32px;
    right:80px;
}

.trip-request-overview .item-list {
    position: absolute;
    top:160px;
    bottom:0;
    right:0;
    left:0;
    overflow-y:auto;
}