.user-dialog {

}

.user-dialog .surrounder {
    padding:8px;
    width:512px;
    display:block;
    text-align:left;
}

.user-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.user-dialog .dialog-title {
    width:100%;
}

.user-dialog h2 {
    width:240px;
    margin:8px 0;
}

.user-dialog .field {
    width:48%;
    margin:8px 2% 8px 0;
}

.user-dialog .info-text {
    margin:8px;
    color:gray;
}