.login {
    position: absolute;
    left:0;
    width:100%;
    height:100%;
    background-image: url("../../images/document-manager-background.jpg");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.login .login-div {
    width:384px;
    margin:128px auto 0 auto;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.5);
    text-align:center;
    padding:32px 16px;
}

.login .input-div {
    background-color:white;
    width:256px;
    height:40px;
    border:0px solid;
    border-radius:3px;
    margin:0 auto 16px auto;
}

.login .input-icon {
    float:left;
    margin:8px;
}

.login .input-field {
    height:100%;
    border:0px;
    width:212px;
    border-radius:3px;
    padding-left:4px;
}

.login .login-div .register-text {
    cursor:pointer;
    color:black;
}

.login .login-button {
    margin:24px 0 8px 0;
    width:256px;
}

.login .apply-button {
    margin:8px 0 16px 0;
    width:256px;
}

.login .logo {
    width:80%;
    margin:32px auto;
}

@media screen and (max-width: 720px) {
    .login {
        background-image: url("../../images/document-manager-background-mobile.jpg");
    }
    .login .login-div {
        margin-top:64px;
        width:256px;
    }
    .login .input-div {
        width:192px;
    }
    .login .input-field {
        width:148px;
    }
    .login .login-button {
        width:192px;
    }
}