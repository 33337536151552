.message {
    width:100%;
    display:inline-block;
}

.message .message-text {
    margin:1px;
    border-radius:3px;
    width:auto;
    max-width:256px;
    padding:3px;
    overflow-wrap: break-word;
}

.message .date-info {
    font-size:10px;
    float:right;
    color:white;
}