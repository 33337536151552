.company-administration {
    width:100%;
    height:100%;
    text-align: left;
}

.company-administration h1 {
    margin:32px 0 0 32px;
}

.company-administration .surrounder {
    position: absolute;
    top:64px;
    bottom:0;
    right:0;
    left:0;
    overflow-y:auto;
    overflow-x: hidden;
    padding-bottom:32px;
}

.company-administration h2 {
    margin:32px 8px 8px 32px;
}

.company-administration .field {
    margin-left:32px;
    margin-bottom: 4px;
    width:160px;
}

.company-administration .file-selector {
    width:auto;
    margin-top:16px;
}

.company-administration .file-button {
    width:192px;
    margin:8px 0 0 16px;
}

.company-administration .save-button {
    position: absolute;
    bottom:16px;
    right:16px;
}

.company-administration .logo-preview {
    width:32px;
    margin:8px 0 0 16px;
}

.company-administration .clear-icon {
    margin:4px;
    cursor:pointer;
}