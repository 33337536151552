.user-menu {
    position:absolute;
    top:0;
    right:0;
    left:0;
    bottom:90%;
    background-color: #D9D9D9;
}

.user-menu .holder {
    margin:2% 4% 0 0;
    float:right;
}

.user-menu .holder li {
    float:left;
    margin-left:16px;
}

.user-menu .search-select {
    margin-top:-16px;
    width:160px;
    text-align: left;
}

.user-menu .icon {
    width:32px;
    height:32px;
}

@media screen and (max-width: 1300px) {
    .user-menu {
        bottom:75%;
    }

    .user-menu .holder {
        margin-top:72px;
    }
}