.list-item {
    width:98%;
    height:64px;
    margin:8px auto;
    text-align:left;
    border-radius:3px;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}

.list-item .info-small {
    color:gray;
    padding:8px 0 0 8px;
}

.list-item .info-big {
    margin:4px 0 0 8px;
}

.list-item .info-tiny {
    color:gray;
    font-size: 10px;
    margin:0 0 0 8px;
}

.list-item .show-icon {
    margin:8px 8px 0 0;
    float:right;
    cursor: pointer;
}

.list-item .delete-icon {
    color:lightcoral;
    margin:8px 8px 0 0;
    float:right;
    cursor: pointer;
}

.list-item .pdf-icon {
    margin:-8px 0 0 0;
}

.list-item .remove-icon {
    margin:-8px 0 0 0;
    color:gray;
}

.list-item .status {
    position: relative;
    float:left;
    width:4px;
    height:100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.pulse {
    animation: pulse 2s ease-in infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0px 3px 1.5px -2px rgba(255,185,0,0.2), 0px 3px 2px 0px rgba(255,185,0,0.14), 0px 1px 4px 0px rgba(255,185,0,0.12);
    }
    50% {
        box-shadow: 0px 3px 6px -2px rgba(255,185,0,0.4), 0px 3px 8px 0px rgba(255,185,0,0.28), 0px 1px 12px 0px rgba(255,185,0,0.24);
    }
    100% {
        box-shadow: 0px 3px 3px -2px rgba(255,185,0,0.2), 0px 3px 4px 0px rgba(255,185,0,0.14), 0px 1px 8px 0px rgba(255,185,0,0.12);
    }
}