@font-face {font-family: "Lato";src: local("Lato"),url("./fonts/Lato/Lato-Regular.ttf") format("truetype");}
@font-face {font-family: "OpenSans";src: local("OpenSans"),url("./fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");}

* {
    margin:0;
    padding:0;
    font-family: 'OpenSans', sans-serif;
    transition: all 300ms;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    font-size:14px;
}

p, li, input, select, option, label, table {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color:#202124;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    text-underline: none;
    color:white;
}

.MuiAutocomplete-popper {
    transition: all 0ms;
    -webkit-transition: all 0ms;
    -moz-transition: all 0ms;
    -o-transition: all 0ms;
}

.modals {
    z-index:2000 !important;
}

.icon {
    font-size:14px;
    cursor:pointer;
    color:#FAB900;
}

.icon:hover {
    opacity:0.8;
}

.delete-icon {
    color:lightcoral;
}

hr {
    height:1px;
    border:none;
    background-color: gray;
}

.fa-trash-alt {
    color:lightcoral;
}

html, body {
    width:100%;
    height:100%;
    font-weight: 300;
    text-align: center;
}

.Mui-disabled {
    color:black !important;
}

i:hover {
    opacity: 0.8;
    cursor:pointer;
}

h1 {
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    color:#202124;
    font-weight: normal;
    text-overflow: ellipsis;
}

h2 {
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: normal;
    color:#202124;
    text-overflow: ellipsis;
}

h3 {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: normal;
    color:#202124;
    text-overflow: ellipsis;
}

.info-text {
    color:gray;
}

/*scrollbar*/
::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
}

::-webkit-scrollbar-track {
    background: white;
}

::-webkit-scrollbar-thumb {
    background: #FAB900;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background: #FAB900;
    opacity:0.8;
}

@media screen and (max-width: 1600px) {
    * {
        font-size:12px;
    }

    p, li, input, select, option, label, table {
        font-size: 12px;
    }

    i {
        font-size: 16px;
    }

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 16px;
    }

    h3 {
        font-size: 14px;
    }
}

@media screen and (max-width: 1300px) {
    * {
        font-size:12px;
    }

    p, li, input, select, option, label, table {
        font-size: 12px;
    }

    i {
        font-size: 16px;
    }

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 16px;
    }

    h3 {
        font-size: 14px;
    }
}
