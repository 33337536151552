.company-report {
    width:512px;
    margin:4px auto;
    text-align: left;
}

.company-report .top-field {
    height:40px;
}

.company-report ul {
    width:100%;
}

.company-report li {
    float:left;
    width:45%;
}

.company-report .icon-holder {
    width:10%;
}

.company-report .field {
    margin:8px 0 0 8px;
    float:left;
}

.company-report .arrow-icon {
    float:right;
    margin:8px 8px 0 0;
    cursor:pointer;
}

.company-report table {
    width:100%;
    margin:4px auto;
    padding:0 8px;
}

.company-report th {
    width:25%;
}