.toolbar {

}

.toolbar .side-menu {
    top:0;
    left:0;
    bottom:0;
    width:15%;
    position:absolute;
    justify-content: space-between;
    align-items:center;
    box-sizing: border-box;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
    z-index: 100;
    background-color: white;
}

.toolbar .logo {
    max-width:80%;
    max-height: 192px;
}

.toolbar .arrow-icon {
    position: absolute;
    top:16px;
    left:16px;
    font-size: 64px;
    margin-top:-16px;
}

.toolbar .side-menu img {
    margin-top:32px;
}

.toolbar .navigation-items {
    position: absolute;
    right:0;
    width:100%;
    top:256px;
}

.toolbar .top-panel {
    position: fixed;
    z-index: 100;
    left:0;
    right:0;
    top:0;
    height:64px;
    background-color: rgba(255,255,255,0.8);
    display:none;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
}

.toolbar .top-panel .menu-icon {
    font-size: 28px;
    float:right;
    margin:18px 18px 0 0;
    color:#fab900;
}

@media screen and (max-width: 1300px) {
    .toolbar .side-menu {
        width:0;
        overflow: hidden;
    }

    .toolbar .top-panel {
        display: block;
    }

    .toolbar .navigation-items {
        top:96px;
    }
}

