.dashboard {

}

.dashboard h1 {
    margin:32px 0;
}

.dashboard h2 {
    margin:16px 0;
}

.dashboard p {
    margin:64px 0 32px 0;
}

.dashboard .button {
    margin:16px;
}

@media screen and (max-width: 800px) {
    .dashboard .holder {
        display:none;
    }
}