.navigation-items {

}

.navigation-items .nav-item {
    margin-top:16px;
    right:0;
    width: 100%;
    height: 48px;
    text-align: left;
    display:inline-block;
}

.navigation-items .nav-item:hover {
    background-color: gray;
}

.navigation-items h2 {
    padding:10px 0 0 15%;
}

.navigation-items .active {
    background-color: gray;
    box-shadow:inset 0 0 5px 1px rgba(0,0,0,0.5);
}

.navigation-items .active a {
     color:white;
}

.navigation-items .icon {
    font-size:inherit;
    margin-bottom:-4px;
}

.navigation-items .icon:hover {
    opacity: 1;
}

.navigation-items .inactive {
    color:gray;
}

.navigation-items .inactive-nav-item {
    margin-top:16px;
    right:0;
    width: 100%;
    height: 48px;
    text-align: left;
    display:inline-block;
    cursor:not-allowed;
}

.navigation-items .inactive-nav-item:hover {
    background-color: lightgray;
}