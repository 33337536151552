.change-trip-dialog {

}

.change-trip-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.change-trip-dialog h2 {
    margin:16px 0 0 16px;
}

.change-trip-dialog .field {
    width:30%;
    margin:2px 1%;
    display: inline-block;
}

.change-trip-dialog .icon {
    margin: 8px 8px 0 0;
}