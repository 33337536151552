.add-item-dialog {
}

.add-item-dialog .dialog-title {
    width:512px;
}

.add-item-dialog .field {
    width:48%;
    margin:8px 1%;
}

.add-item-dialog .multiline {
    width:496px;
}

.add-item-dialog .switch {
    width:200px;
    margin:16px 0 0 8px;
}

.add-item-dialog .add-attribute-button {
    width:256px;
    margin: 8px auto;
}

.add-item-dialog .button {
    margin:16px;
    float:right;
}

.add-item-dialog h2 {
    margin:8px;
}

.checkbox {
    padding:8px 0 0 0;
}