.settings {
    width:100%;
    height:100%;
    text-align: left;
}

.settings h1 {
    margin:32px 0 0 32px;
}

.settings .input {
    margin-left:32px;
    margin-top:8px;
}

.settings .account-information {
    margin:8px 0 0 32px;
}

.settings .postbox-button {
    margin:16px 32px;
}

.settings .save-button {
    position: absolute;
    bottom:16px;
    right:16px;
}

.settings .message-input {
    width:94%;
    margin-left:32px;
    margin-top:8px;
}