.bottom-panel {
    position:absolute;
    left:15.5%;
    bottom:1%;
    right:23%;
    top:71%;
    background-color: white;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
    display: flex;
}

.bottom-panel .field {
    margin:8px 0 0 8px;
    width:128px;
    float:left;
}

@media screen and (max-width: 1300px) {
    .bottom-panel {
        display: none;
    }
}