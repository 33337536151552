.trip-dialog {

}

.trip-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.trip-dialog .return-trip-title {
    margin:16px 0 0 16px;
}

.trip-dialog h2 {
    margin:16px 0 0 16px;
}

.trip-dialog .field {
    width:48%;
    margin:2px 1%;
    display: inline-block;
}

.trip-dialog .multiline {
    width:98%;
    margin:2px 1%;
}

.trip-dialog .icon {
    margin: 8px 8px 0 8px;
}

.trip-dialog .changed-info-text {
    font-size: 9px;
    color:#fab900;
}

@media screen and (max-width: 600px) {
    .trip-dialog .field {
        width:98%;
    }
}