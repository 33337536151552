.footer {
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    height:64px;
    background-color: rgb(17,17,17);
}

.footer p {
    margin-top:16px;
    color:white;
}