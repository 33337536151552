.file-dialog {

}

.file-dialog .close-icon {
    position: absolute;
    top:8px;
    right: 8px;
}

.file-dialog .button {
    margin:8px 16px;
}