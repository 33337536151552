.chat-overview {
    height:512px;
    position: relative;
}

.chat-overview .user-overview {
    position:absolute;
    top:8px;
    bottom:132px;
    width:200px;
    left:0;
    overflow-x:scroll;
}

.chat-overview .user-overview .company-contacts-item {
    width:160px;
    height:64px;
    box-shadow: 0 0 3px 3px rgba(0,0,0,0.2);
}

.chat-overview .user-overview .user-contacts-item {
    width:128px;
    height:48px;
    margin-left:8px;
}

.chat-overview .user-overview .contacts-item {
    cursor:pointer;
    margin:16px 0 0 16px;
}

.chat-overview .user-overview .contacts-item:hover {
    scale:1.1;
}

.chat-overview .user-overview .contacts-item p {
    padding:8px 0 0 8px;
}

.chat-overview .message-panel {
    position:absolute;
    top:16px;
    bottom:128px;
    left:200px;
    right:0;
    overflow-x:auto;
}

.chat-overview .send-panel {
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    height:128px;
}

.chat-overview .multiline {
    width:100%;
    height:20%;
}

.chat-overview .send-button {
    position:absolute;
    right:8px;
    bottom:8px;
}

.chat-overview .notification {
    animation: notify 2s ease-in infinite;
}

@keyframes notify {
    0% {
        box-shadow: 0px 0px 4px 4px rgba(255,185,0,0.4);
    }
    50% {
        box-shadow: 0px 0px 8px 8px rgba(255,185,0,0.8);
    }
    100% {
        box-shadow: 0px 0px 4px 4px rgba(255,185,0,0.4);
    }
}