.side-panel {
    position:absolute;
    top:12%;
    right:1%;
    bottom:1%;
    width:21.5%;
    background-color: white;
    z-index:10;
    overflow-x:scroll;
}

.side-panel h1 {
    margin:16px auto;
}

@media screen and (max-width: 1300px) {
    .side-panel {
        display: none;
    }
}