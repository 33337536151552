.App {
    height:100%;
    width:100%;
}

.app-content {
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:64px;
}