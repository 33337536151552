.security-settings {
    width:100%;
    height:100%;
    text-align:left;
}

.security-settings h1 {
    margin:32px 0 0 32px;
}

.security-settings .search-input {
    margin:16px 0 0 32px;
}

.security-settings .search-select {
    margin:16px 0 0 16px;
}

.security-settings .search-button {
    margin:24px 0 0 16px;
}

.security-settings .download-icon {
    float:right;
    margin:32px 32px 0 0;
}

.security-settings .item-list {
    position: absolute;
    top:160px;
    bottom:0;
    right:0;
    left:0;
    overflow-y:auto;
}

.security-settings .upload-fab {
    position:absolute;
    bottom:32px;
    right:96px;
    background-color: #DABB88;
}

.security-settings .add-fab {
    position:absolute;
    bottom:32px;
    right:80px;
}

.security-settings .info-icon {
    position:absolute;
    top:8px;
    right:8px;
}