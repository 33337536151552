.common-settings {
    width:100%;
    height:100%;
    text-align: left;
}

.common-settings h1 {
    margin:32px 0 0 32px;
}

.common-settings .surrounder {
    position: absolute;
    top:64px;
    bottom:0;
    right:0;
    left:0;
    overflow-y:auto;
    overflow-x: hidden;
    padding-bottom:32px;
    padding-left:32px;
}

.common-settings h2 {
    margin-top:32px;
}

.common-settings .field {
    width:160px;
    margin:8px 16px 8px 0;
}

.common-settings .multiline {
    width:95%;
}